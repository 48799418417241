<template>
  <div class="site-desc-container">
    <div class="about-index-title-div">
      全球新能源汽车家庭智能充电和绿色数字能源领军企业
    </div>
    <div class="about-desc-ul-div hostoty-child-ul-div">
      上海挚达科技发展股份有限公司（以下简称“挚达科技”）成立于2010年，是全球新能源汽车家庭智能充电和绿色数字能源领军企业，致力于以“充电”为入口，推动新能源汽车和绿色能源进入全球家庭，为人类可持续发展贡献力量。
    </div>
    <div class="about-desc-ul-div hostoty-child-ul-div">
      挚达科技入选了国家级专精特新“小巨人”，是大型新能源车企与能源公司战略投资的科技创业公司，被沙利文授予"家用充电桩全球销量第一"市场地位确认书。挚达科技着眼全球，聚合产品、服务、数字化、制造及品牌“五大”核心竞争力，打造充电桩、绿色数字能源解决方案和自动充电机器人“三大”产品线，为实现“全球化、数字化、智能化”的挚达2.0战略而努力。
    </div>
    <div class="about-index-title-div margin-top-20">使命与愿景</div>
    <div class="about-desc-ul-div hostoty-child-ul-div">
      使命：以“充电”为入口，推动电动汽车和绿色能源进入全球的家庭，促进人类可持续发展。
    </div>
    <div class="about-desc-ul-div hostoty-child-ul-div">
      愿景：致力于成为全球电动汽车家庭智能充电和绿色能源科技领军企业
    </div>

    <div class="img-show-flex-div">
      <div class="img-child-show-div">
        <img
          src="https://img.cdn.wxzhida.cn/shzhida-site/sitedesc/img-01.png"
          class="img-show"
        />
      </div>

      <div class="img-child-show-div margin-10">
        <img
          src="https://img.cdn.wxzhida.cn/shzhida-site/sitedesc/img-02.png"
          class="img-show"
        />
      </div>

      <div class="img-child-show-div margin-10">
        <img
          src="https://img.cdn.wxzhida.cn/shzhida-site/sitedesc/img-03.png"
          class="img-show"
        />
      </div>

      <div class="img-child-show-div">
        <img
          src="https://img.cdn.wxzhida.cn/shzhida-site/sitedesc/img-04.png"
          class="img-show"
        />
      </div>
    </div>

    <div class="about-index-title-div margin-top-20">发展历程</div>

    <div class="history-list-div">
      <ul class="hostoty-child-ul-div">
        2010年，成立于上海市杨浦区
      </ul>
      <ul class="hostoty-child-ul-div">
        2011年，开发第一代电动汽车分时共享平台
      </ul>
      <ul class="hostoty-child-ul-div">
        2014年，成为特斯拉首批签约安装服务商；成为保时捷官方安装服务商
      </ul>
      <ul class="hostoty-child-ul-div">
        2015年，德国电动出行协会BEM在挚达设置中国代表处；成为宝马官方安装服务商；第一期车规级充电桩生产基地落户安徽宣城
      </ul>
      <ul class="hostoty-child-ul-div">
        2016年，成功发布首款家庭智能充电产品：守望者系列，获CIDI工业设计奖
      </ul>
      <ul class="hostoty-child-ul-div">
        2017年，在无锡成立数字化平台公司，专注家庭数字化平台研发
      </ul>
      <ul class="hostoty-child-ul-div">
        2018年，成为上汽大众原厂充电桩供应商和官方安装服务商
      </ul>
      <ul class="hostoty-child-ul-div">
        2020年，成功发布全球标智能充电产品：金刚，获2020年中国数字经济科技创新明星产品奖；成为比亚迪原厂充电桩供应商和官方安装服务商、海外独家提供商
      </ul>
      <ul class="hostoty-child-ul-div">
        2021年，中国最大的新能源企业国家电投入股挚达；荣获国家级专精特新小巨人殊荣；登录各大电商平台，网销第一
      </ul>
      <ul class="hostoty-child-ul-div">
        2022年，获中国最大的新能源汽车品牌比亚迪战略投资；年产200万套车规级充电桩的二期生产基地落户安庆；完成股份制改革，正式更名为“上海挚达科技发展股份有限公司”
      </ul>
      <ul class="hostoty-child-ul-div">
        2023年，进行规模化海外部署，落地第一个海外工厂-泰国工厂；正式推出方挚达家庭绿色数字能源解决案，电动车充绿电，环保又省钱
      </ul>
    </div>

    <div class="img-show-flex-div">
      <div class="img-child-show-div">
        <img
          src="https://img.cdn.wxzhida.cn/shzhida-site/sitedesc/img-05.png"
          class="img-show"
        />
      </div>

      <div class="img-child-show-div margin-10">
        <img
          src="https://img.cdn.wxzhida.cn/shzhida-site/sitedesc/img-06.png"
          class="img-show"
        />
      </div>

      <div class="img-child-show-div margin-10">
        <img
          src="https://img.cdn.wxzhida.cn/shzhida-site/sitedesc/img-07.png"
          class="img-show"
        />
      </div>

      <div class="img-child-show-div"></div>
    </div>

    <div class="about-index-title-div margin-top-20">主要产品</div>

    <div class="product-list-div">
      <ul class="hostoty-child-ul-div">
        家充系列：开拓者Pro、开拓者Air、守护者、挑战者、守望者、探索者、金刚
      </ul>
      <ul class="hostoty-child-ul-div">
        出行系列：挚达神行侠户外出行套包、挚达小黑侠便携桩
      </ul>
      <ul class="hostoty-child-ul-div">
        充电机器人系列：FS蛇形充电机器人、FA固定式抓枪充电机器人、MA移动式充电机器人
      </ul>
      <ul class="hostoty-child-ul-div">
        直流桩系列：120-240kW直流桩
      </ul>
      <ul class="hostoty-child-ul-div">
        安装及售后服务
      </ul>
      <ul class="hostoty-child-ul-div">
        海外产品系列：ZeedaWallbox、ZeedaPortable、ZeedaHome
      </ul>
    </div>

    <div class="img-show-flex-div set-height-100">
      <div class="img-child-pro-show-div">
        <img
          src="https://img.cdn.wxzhida.cn/shzhida-site/sitedesc/img-08.png"
          class="img-show"
        />
      </div>

      <div class="img-child-pro-show-div margin-10">
        <img
          src="https://img.cdn.wxzhida.cn/shzhida-site/sitedesc/img-09.png"
          class="img-show"
        />
      </div>

      <div class="img-child-pro-show-div margin-10">
        <img
          src="https://img.cdn.wxzhida.cn/shzhida-site/sitedesc/img-10.png"
          class="img-show"
        />
      </div>
      <div class="img-child-pro-show-div margin-10">
        <img
          src="https://img.cdn.wxzhida.cn/shzhida-site/sitedesc/img-11.png"
          class="img-show"
        />
      </div>

      <div class="img-child-pro-show-div margin-10">
        <img
          src="https://img.cdn.wxzhida.cn/shzhida-site/sitedesc/img-12.png"
          class="img-show"
        />
      </div>

      <div class="img-child-pro-show-div margin-10">
        <img
          src="https://img.cdn.wxzhida.cn/shzhida-site/sitedesc/img-13.png"
          class="img-show"
        />
      </div>
    </div>

    <div class="about-index-title-div margin-top-20">荣誉奖项</div>

    <div class="flex-div">
      <div class="product-list-div">
        <ul class="hostoty-child-ul-div">
          国家级专精特新“小巨人”企业
        </ul>
        <ul class="hostoty-child-ul-div">
          上海市高新技术企业
        </ul>
        <ul class="hostoty-child-ul-div">
          上海市市级工业设计中心
        </ul>
        <ul class="hostoty-child-ul-div">
          沙利文“家用充电桩全球销量第一”市场地位确认证书
        </ul>
        <ul class="hostoty-child-ul-div">
          2024 iF产品类设计奖
        </ul>
      </div>
      <div class="img-child-dw-show-div">
        <img
          src="https://img.cdn.wxzhida.cn/shzhida-site/sitedesc/img-14.png"
          class="img-show"
        />
      </div>
    </div>

    <div class="about-index-title-div margin-top-20">行业地位</div>

    <div class="product-list-div">
      <ul class="hostoty-child-ul-div">
        于2021.1-2023.9期间，全球已累计出货90万台家用电动汽车充电桩及在中国已累计出货80万台家用电动汽车充电桩。
      </ul>
      <ul class="hostoty-child-ul-div">
        于2021.1-2023.9期间，完成合计60万次安装及售后服务工作。
      </ul>
      <ul class="hostoty-child-ul-div">
        打造车规级产品，生产线全线采用数字化MES系统，生产管理流程经汽车行业标准IATF16949认证，2023综合年产能可达200万台（套）。
      </ul>
      <ul class="hostoty-child-ul-div">
        截至2023年9月30日，服务网点遍布全国360个城市，安装及售后服务可覆盖全国所有乡村。
      </ul>
      <ul class="hostoty-child-ul-div">
        根据弗若斯特沙利文的资料，于2021.1-2023.9期间，按家用电动汽车充电桩销量计，中国市场占有率达20.5%，全球市场占有率达到12.2%。
      </ul>
    </div>

    <div class="img-show-flex-div set-height-100">
      <div class="img-child-show-div">
        <img
          src="https://img.cdn.wxzhida.cn/shzhida-site/sitedesc/img-15.png"
          class="img-show"
        />
      </div>

      <div class="img-child-show-div margin-10"></div>

      <div class="img-child-show-div margin-10"></div>
      <div class="img-child-show-div"></div>
    </div>

    <div class="about-index-title-div margin-top-20">经营范围</div>

    <div class="product-list-div">
      <ul class="hostoty-child-ul-div">
        包括：新能源、新材料、环保科技、节能科技、计算机、网络科技领域内的技术开发、技术转让、技术咨询、技术服务；环保设备、燃料油的批发与零售；汽车相关零部件、机电产品、化工产品（除危险化学品、监控化学品、烟花爆竹、民用爆炸物品、易制毒化学品）、电子产品的开发与销售；汽车租赁；电动车辆及零部件的开发、销售与租赁；实业投资，投资管理咨询；机械设备维修；广告设计、制作、代理、发布；建筑装修装饰建设工程专业施工，新能源汽车充换电运营服务，新能源汽车充换电设施建设运营，从事货物与技术的进出口业务等。
      </ul>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive, watch } from "vue";
import { useStore } from "vuex";
import config from "../../libs/config";
export default {
  name: "About",
  components: {},
  setup() {
    const { proxy } = getCurrentInstance();
    const store = useStore();
    const state = reactive({
      jxDataList: config.jxDataList,
    });
    watch(
      () => store.state.curPageTarget,
      (newValue, oldValue) => {
        console.log("值发生了变更", newValue, oldValue);
        const jumpToTarget = newValue;
        if (jumpToTarget) {
          document.querySelector("#" + jumpToTarget).scrollIntoView({
            behavior: "smooth",
            // 定义动画过渡效果， "auto"或 "smooth" 之一。默认为 "auto"
            block: "start",
            // 定义垂直方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "start"
            inline: "nearest",
            // 定义水平方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "nearest"
          });
        }
      },
      {
        deep: true,
      }
    );
    onMounted(() => {
      document.title = "关于我们";
      // 滚动到页面最上方
      window.scrollTo(0, 0);
      const jumpToTarget = proxy.$route.query.pagePostion;
      if (jumpToTarget) {
        document.querySelector("#" + jumpToTarget).scrollIntoView({
          behavior: "smooth",
          // 定义动画过渡效果， "auto"或 "smooth" 之一。默认为 "auto"
          block: "start",
          // 定义垂直方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "start"
          inline: "nearest",
          // 定义水平方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "nearest"
        });
      }
      $(".about_qh").flexslider({
        animation: "slide",
        start: function (slider) {
          $("body").removeClass("loading");
        },
      });
      var options = {
        useEasing: true,
        useGrouping: true,
        separator: ",",
        decimal: ".",
        prefix: "",
        suffix: "",
      };
      var options2 = {
        useEasing: true,
        useGrouping: true,
        separator: ",",
        decimal: ".",
        decimalPlaces: ".",
        prefix: "",
      };
      var demo = new CountUp("a_s1", 0, 360, 0, 2, options);
      var demo2 = new CountUp("a_s2", 0, 20.5, 1, 3, options2);
      // var demo3 = new CountUp('a_s3', 0, 27.7, 1, 3, options2)
      var demo4 = new CountUp("a_s4", 0, 90, 0, 6, options);
      $(window).scroll(function () {
        if ($(window).scrollTop() >= 300) {
          demo.start();
          demo2.start();
          // demo3.start()
          demo4.start();
        }
      });
      $(".flexslider").flexslider({
        animation: "slide",
        start: function (slider) {
          $("body").removeClass("loading");
        },
      });
      $(".ck-slide").ckSlide({
        autoPlay: false, //true
      });
      $(window).scroll(function () {
        //为页面添加页面滚动监听事件
        var wst = $(window).scrollTop(); //滚动条距离顶端值
        for (var i = 1; i < 10; i++) {
          if ($("#a" + i) && $("#a" + i).offset()) {
            //加循环
            if ($("#a" + i).offset().top <= wst) {
              //判断滚动条位置
              $("#a" + i).addClass("on x"); //给当前导航加c类
            } else {
              //$("#a"+i).removeClass('on');
              $("#a" + i).removeClass("x");
            }
          }
        }
      });
      console.log(state.jxDataList);
    });
    return {
      state,
    };
  },
};
</script>

<style scoped>
.fact-view-title-div {
  display: flex;
}
.right-flex-img-div {
  margin-top: 50px;
}
.fact-title-child-div {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 1.125rem;
}
.flex-div {
  display: flex;
}
.left-fact-title-div {
  margin-right: 50px;
  margin-top: 120px;
  flex: 1;
}
.img-child-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12px;
}
.factory-view-img {
  width: 500px;
}

.site-desc-container {
  width: 78%;
  margin: 88px auto 50px;
}

.about-index-title-div {
  font-size: 24px;
  margin-bottom: 12px;
  line-height: 32px;
  font-weight: 500;
  color: #1a1a1a;
}

ul.hostoty-child-ul-div::before {
  content: "";
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 50%;
  background: #1a1a1a;
  vertical-align: middle;
  margin-right: 10px;
}

.about-desc-ul-div::before {
  content: "";
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 50%;
  background: #1a1a1a;
  vertical-align: middle;
  margin-right: 10px;
}

.hostoty-child-ul-div {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
}
.margin-top-20 {
  margin-top: 20px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.img-show-flex-div {
  display: flex;
  margin: 16px 0 20px 0;
}

.img-child-show-div {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 200px;
}

.img-child-pro-show-div {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 120px;
}

.margin-10 {
  margin: 0 20px;
}

.img-show {
  width: 100%;
}

.img-child-dw-show-div {
  height: 240px;
  margin-left: 100px;
  margin-top: -40px;
}
</style>
